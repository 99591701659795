<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-wayspa">
        <img src="@/assets/casestudy/wayspa-logo.png" alt="Wayspa">
        <span class="sep"></span>
        <p>Founded in 2003, WaySpa is the world's fastest-growing online spa marketplace. In partnership with spas across Canada, WaySpa offers you competitive pricing and the knowledge you need to
          make informed choices.
          WaySpa's industry leading search tools help you find spa treatments and packages and compare spas in your neighbourhood quickly and easily. Whether you purchase a single treatment, exclusive
          combo packages, or versatile gift certificates, shopping at WaySpa is the ultimate in convenience.</p>
      </div>
    </div>

    <div class="laptop-img wayspa-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/wayspa-main-img.png" alt="Wayspa">
      </div>
    </div>

    <div class="container-about wayspa-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/wayspa-diamond.svg" alt="Wayspa">
        <h3>About the project</h3>
        <p>We had multiple projects that involved the mother company and other subsidiaries. As the biggest SPA directory in Canada, they wanted a new look so we got called to code the new design with
          an interesting timeline of one month and then hand it off for back-end implementation.<br/>
          In the meantime, the company got purchased by a conglomerate and the implementation got paused but we are definitely proud of our work and we hope that it will be pushed to the world wide
          web soon.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-services__content">
          <h3>What we do</h3>
          <div class="wwd-left">
            <h4>Managed services</h4>
            <img src="@/assets/casestudy/web-app-g.svg" width="60px" height="60px">
            <h4>Support</h4>
            <img src="@/assets/casestudy/maintenance-icon-g.svg" width="60px" height="60px">
            <h4>SEO</h4>
            <img src="@/assets/casestudy/consultancy-icon-g.svg" width="60px" height="60px">
          </div>
          <span class="sep vertical"></span>
          <div class="wwd-right">
            <img src="@/assets/casestudy/managed-services-g.svg" width="60px" height="60px">
            <h4>Custom Web App's</h4>
            <img src="@/assets/casestudy/support-icon-g.svg" width="60px" height="60px">
            <h4>Maintenance</h4>
            <img src="@/assets/casestudy/seo-icon-g.svg" width="60px" height="60px">
            <h4>Consultancy</h4>
          </div>
        </div>
      </div>
    </div>

    <testimonial backgroundTsColor="#f72e59" tsTitle="#fafafa">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Frederick Lemire-Collu, CTO</p>
        <p class="testimonial-text__content">"The level of expertise and professionalism that Vali and Scriptics have demonstrated throughout the multitude of projects that we've collaborated on has
          been outstanding. From design to development, they have proven themselves time and time again" </p>
      </div>
    </testimonial>


    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Avigora'}" class="left">
              <img src="@/assets/casestudy/btn-avigora.jpg" alt="Avigora">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name:'Careful'}" class="right">
              <img src="@/assets/casestudy/btn-careful.jpg" alt="Careful">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#50b0ac"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Wayspa",
  components: {LetsChat, Testimonial, ContactUs, Footer}
}
</script>

<style lang="scss">
.logo-wayspa img {
  max-width: 320px;
}

.wayspa-laptop {
  background: #50b0ac;
}

.wayspa-about {
  background: #F3F3F3;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #50b0ac 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>